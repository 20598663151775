<template>
  <section class="responsive-section" @scroll="handleScroll">
    <div class="logo-container">
      <img 
        src="../assets/section-one/Logo Colgate.png" 
        alt="Logo" 
        class="logo animate-on-scroll" 
        ref="logo"
      >
    </div>
    <div class="content">
      <div class="left-container">
        <img 
          src="../assets/section-seven/Título.png" 
          alt="Left Image" 
          class="content-image animate-on-scroll" 
          ref="titulo"
        >
      </div>
      <div class="right-container">
        <div class="right-inner-container">
          <div class="additional-above-textos">
            <img 
              src="../assets/section-seven/hombreConSensibilidad.png" 
              alt="Additional Above Textos Image" 
              class="above-textos-image animate-on-scroll clickable" 
              ref="hombreConSensibilidad" 
              @click="showCenterImage"
            >
          </div>
          <img 
            src="../assets/section-seven/Textos.png" 
            alt="Right Image" 
            class="content-image animate-on-scroll" 
            ref="textos"
          >
        </div>
      </div>
    </div>
    <div class="center-image-container">
      <img 
        v-show="centerImageVisible"
        src="../assets/section-seven/gráfico.png" 
        alt="Center Image" 
        class="center-image animate-dynamic-entry" 
        :class="{ visible: centerImageVisible }"
        ref="grafico"
      >
    </div>
    <div class="additional-images-container">
      <img 
        src="../assets/section-seven/textos 2.png" 
        alt="Additional Image 1" 
        class="additional-image animate-on-scroll" 
        ref="textos2"
      >
      <img 
        src="../assets/section-seven/Claim.png" 
        alt="Additional Image 2" 
        class="additional-image animate-on-scroll" 
        ref="claim"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionSeven",
  data() {
    return {
      centerImageVisible: false,
    };
  },
  methods: {
    handleScroll() {
      const elements = [
        this.$refs.logo,
        this.$refs.titulo,
        this.$refs.hombreConSensibilidad,
        this.$refs.textos,
        this.$refs.textos2,
        this.$refs.claim
      ];

      elements.forEach((el) => {
        if (el) {
          const rect = el.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom > 0) {
            el.classList.add("visible");
          }
        }
      });
    },
    showCenterImage() {
      this.centerImageVisible = true;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.responsive-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../assets/section-seven/fondo.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.animate-on-scroll {
  opacity: 0;
  transform: scale(0.7) translateY(100px);
  transition: opacity 2.5s ease-in-out, transform 2.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.animate-dynamic-entry {
  opacity: 0;
  transform: scale(0.5) rotate(-45deg) translateX(-100px) translateY(100px);
  transition: opacity 3.5s ease-in-out, transform 3.5s cubic-bezier(0.65, 0, 0.35, 1);
}

.animate-dynamic-entry.visible {
  opacity: 1;
  transform: scale(1) rotate(0deg) translateX(0) translateY(0);
}

.logo-container {
  position: absolute;
  top: 0;
  left: 50px;
}

.logo {
  width: 100px;
  height: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: auto;
}

.left-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-container {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-inner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.additional-above-textos {
  width: 80%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.above-textos-image {
  width: 67%;
  height: auto;
  cursor: pointer;
}

.content-image {
  width: 80%;
  height: auto;
}

.center-image-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.center-image {
  width: 50%;
  height: auto;
  margin-top: -2%;
}

.additional-images-container {
  position: absolute;
  bottom: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.additional-image {
  width: 40%;
  height: auto;
  margin-top: 10px;
}

@media screen and (max-width: 1180px) and (max-height: 820px), screen and (max-width: 1024px) {
  .responsive-section {
    flex-direction: column;
    height: 820px;
  }

  .content {
    flex-direction: row;
  }

  .left-container, .right-container {
    width: 50%;
  }

  .right-inner-container {
    width: 100%;
  }

  .left-container .content-image {
    width: 60%;
    margin-top: 25%;
  }

  .right-container .content-image {
    width: 90%;
  }

  .center-image {
    width: 80%;
  }

  .additional-image {
    width: 75%;
  }

  .logo {
    width: 177px;
  }
}
</style>
