<template>
  <section class="responsive-section">
    <img src="../assets/section-one/Logo Colgate.png" alt="Logo" class="logo animated-entry" />
    <div class="content-container">
      <div class="left-container">
        <!-- Imágenes dentro del contenedor izquierdo -->
        <img 
          src="../assets/section-eight/título.png" 
          alt="Imagen 1 en el lado izquierdo" 
          class="left-image animated-entry"
        />
        <img 
          src="../assets/section-eight/textos.png" 
          alt="Imagen 2 en el lado izquierdo" 
          class="left-image animated-entry"
        />
      </div>
      <div class="right-container">
        <!-- Imagen en el contenedor derecho -->
        <img 
          src="../assets/section-eight/Productos.png" 
          alt="Imagen en el lado derecho" 
          class="right-image animated-scale"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionEight",
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.2, // Se activa cuando el 20% del elemento es visible
      }
    );

    const elements = document.querySelectorAll(".animated-entry");
    elements.forEach((el) => observer.observe(el));
  },
};
</script>

<style scoped>
/* Estilo general del contenedor */
.responsive-section {
  position: relative;
  background-image: url("../assets/section-eight/fondo.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Estilo del logo */
.logo {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 100px;
  height: auto;
}

/* Estilo del contenedor principal */
.content-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* Estilo de los contenedores izquierdo y derecho */
.left-container,
.right-container {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Estilo de las imágenes dentro del contenedor izquierdo */
.left-image {
  max-width: 74%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

/* Estilo de la imagen dentro del contenedor derecho */
.right-image {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
}

/* Animaciones para las imágenes seleccionadas */
.animated-scale {
  animation: scaleEffect 6s infinite ease-in-out;
}

@keyframes scaleEffect {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

/* Animaciones de entrada */
.animated-entry {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.animated-entry.in-view {
  opacity: 1;
  transform: translateY(0);
}

/* Media query específica para formato 1180x820 */
@media screen and (max-width: 1180px) and (max-height: 820px) {
  .responsive-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left-container,
  .right-container {
    width: 50%;
    margin-bottom: 0;
  }

  .logo {
    width: 15%;
  }

  .right-image {
    max-width: 80%;
  }
}

/* Media query para tablets o pantallas medianas */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .content-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left-container,
  .right-container {
    width: 50%;
  }

  .left-image {
    max-width: 74%;
  }

  .right-image {
    max-width: 70%;
  }
}
</style>
