<template>
  <section class="background-section">
    <img class="logo" src="../assets/section-one/Logo Colgate.png" alt="Logo" />
    <div class="left-container">
      <img class="left-image top animated-element" src="../assets/section-six/Logo Cepillo.png" alt="Left Top Image" />
      <img class="left-image bottom animated-element" src="../assets/section-six/Texto cerdas.png" alt="Left Bottom Image" />
    </div>

    <div class="right-container">
      <img class="right-image animated-element" src="../assets/section-six/Texto2.png" alt="Right Image" />
      <div class="right-div">
        <a href="https://drive.google.com/file/d/1lE9ng_UgHG-qc4U2n6rXMxvGCZpmEuEN/view?usp=drive_link">
          <img class="animated-element" src="../assets/section-six/BotonVideo.png" width="100%" alt="" />
        </a>
      </div>
    </div>

    <img class="center-image animated-element" src="../assets/section-six/Círcilo fondo.png" alt="Centered Image" />
    <img class="overlay-image animated-overlay" src="../assets/section-six/Cepillo Dental.png" alt="Overlay Image" ref="overlayImage" />
    <img class="top-right-image animated-element" src="../assets/section-six/Banner Nuevo.png" alt="Top Right Image" />

    <div class="footer-text animated-element">*Causados por bacterias vs un cepillo regular, desde la primera semana de uso.</div>
  </section>
</template>

<script>
export default {
  name: "BackgroundSection",
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            element.classList.add("animate-entry");
            observer.unobserve(element); // Detach observer after animation triggers
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    // Observe all animated elements
    const elements = document.querySelectorAll(".animated-element, .animated-overlay");
    elements.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style scoped>
.background-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../assets/section-four/Fondo.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 15%;
  height: auto;
}

.left-container {
  position: absolute;
  left: 48px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  z-index: 2;
}

.left-image {
  width: 100px;
  height: auto;
}

.right-container {
  width: 34%;
  height: auto;
  position: absolute;
  right: 48px;
  top: 73%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  z-index: 2;
}

.right-image {
  width: 100%;
  height: auto;
}

.right-div {
  width: 100px;
  height: auto;
  background-size: cover;
  background-position: center;
  object-fit: contain;
}

.center-image {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.overlay-image {
  position: absolute;
  max-width: 60%;
  max-height: 60%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.6s ease, transform 2.5s ease;
}

.animated-overlay.animate-entry {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  animation: wiggle 2.5s ease-in-out infinite 1.5s;
  top: 50%;
  left: 50%;
  transform-origin: center;
}

.animated-element {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease, transform 1.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.animated-element.animate-entry {
  opacity: 1;
  transform: translateY(0);
  animation: fade-slide 3s ease-in-out forwards;
}

@keyframes fade-slide {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
  25% {
    transform: translate(-50%, -50%) scale(1) rotate(3deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1) rotate(-3deg);
  }
}

.top-right-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: auto;
}

.footer-text {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  color: #1a3467;
  z-index: 2;
}

@media screen and (max-width: 1180px) and (max-height: 820px) {
  .background-section {
    height: 820px;
  }

  .logo {
    top: 0;
    left: 50px;
    width: 15%;
  }

  .left-container {
    padding: 10px;
  }

  .left-image {
    width: 44%;
  }

  .right-container {
    padding: 10px;
  }

  .right-image {
    width: 100%;
  }

  .right-div {
    width: 60%;
    height: auto;
  }

  .center-image {
    max-width: 78%;
    max-height: 78%;
  }

  .overlay-image {
    max-width: 82%;
    max-height: 82%;
  }

  .top-right-image {
    width: 280px;
    right: 0;
    top: 90px;
  }

  .footer-text {
    font-size: 14px;
  }
}
</style>
