SectionTen.vue con animaciones

<template>
  <section class="hero-section" ref="section">
    <img
      src="../assets/section-one/Logo Colgate.png"
      alt="Logo Colgate"
      class="logo"
      ref="logo"
    />
    <img
      src="../assets/section-ten/Título_1.png"
      alt="Título"
      class="title"
      ref="title"
    />
    <div class="container">
      <div class="grid-container left-container">
        <div class="image-row">
          <img
            src="../assets/section-ten/Fluoruro de fosfato.png"
            alt="Imagen Izquierda 1"
            class="left-image-1 hidden"
            ref="fluoride"
          />
          <img
            src="../assets/section-ten/Orthogard.png"
            alt="Imagen Izquierda 2"
            class="left-image-2 breathe" 
            ref="orthogard"
            @click="handleOrthogardClick"
          />
        </div>
        <img
          src="../assets/section-ten/Banner OrthoGard_2.png"
          alt="Imagen Izquierda"
          class="banner-left hidden"
          ref="bannerOrtho"
        />
      </div>
      <div class="grid-container right-container">
        <div class="image-row">
          <img
            src="../assets/section-ten/Duraphat.png"
            alt="Imagen Derecha 1"
            class="right-image-1 breathe"
            ref="duraphat"
            @click="handleDuraphatClick"
          />
          <img
            src="../assets/section-ten/Fluoruro de Sodio.png"
            alt="Imagen Derecha 2"
            class="right-image-2 hidden"
            ref="fluorideSodium"
          />
        </div>
        <img
          src="../assets/section-ten/Banne Duraphat.png"
          alt="Imagen Derecha"
          class="banner-right hidden"
          ref="bannerDuraphat"
        />
      </div>
    </div>
    <p style="margin-top: 20px;">1. B.W.M. van Swaaij, D.E. Slot, G.A. Van der Weijden, M.F. Timmerman, J. Ruben, Fluoride, pH Value, and Titratable Acidity of Commercially Available Mouthwashes, International Dental Journal, Volume 74, Issue 2, 2024, Pages 260-267.</p>
    <p>2. Baik A, Alamoudi N, EL-Housseiny A, Altuwirqi A. Fluoride Varnishes for Preventing Occlusal Dental Caries: A Review. Dent J (Basel). 2021 Jun 3;9(6):64.</p>
  </section>
</template>

<script>
export default {
  name: "SectionTen",
  methods: {
    handleOrthogardClick() {
      this.$refs.fluoride.classList.remove("hidden");
      this.$refs.fluoride.classList.add("flip-in-x");
      this.$refs.bannerOrtho.classList.remove("hidden");
      this.$refs.bannerOrtho.classList.add("flip-in-x");
    },
    handleDuraphatClick() {
      this.$refs.fluorideSodium.classList.remove("hidden");
      this.$refs.fluorideSodium.classList.add("flip-in-x");
      this.$refs.bannerDuraphat.classList.remove("hidden");
      this.$refs.bannerDuraphat.classList.add("flip-in-x");
    },
  },
  mounted() {
    const options = {
      root: null, // Observa en el viewport del navegador
      threshold: 0.5, // 50% del elemento debe estar visible para activar la animación
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Animación para el logo y título
          if (entry.target === this.$refs.logo) {
            entry.target.classList.add("fade-in-down");
          }
          if (entry.target === this.$refs.title) {
            entry.target.classList.add("fade-in-down");
          }

          // Animación para Orthogard y Duraphat
          /* if (entry.target === this.$refs.orthogard) {
            entry.target.classList.add("wiggle");
          }
          if (entry.target === this.$refs.duraphat) {
            entry.target.classList.add("wiggle");
          } */
        }
      });
    }, options);

    // Observa los elementos
    observer.observe(this.$refs.logo);
    observer.observe(this.$refs.title);
/*     observer.observe(this.$refs.orthogard);
    observer.observe(this.$refs.duraphat); */
  },
};
</script>

<style scoped>
.hero-section {
  position: relative;
  width: 100%;
  height: auto;
  background-image: url("../assets/section-ten/Fondo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 15%;
  height: auto;
  opacity: 0; /* Ocultar inicialmente */
  transform: translateY(-50px); /* Posición inicial de la animación */
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.title {
  margin-top: 50px;
  width: 80%;
  max-width: 800px;
  height: auto;
  opacity: 0; /* Ocultar inicialmente */
  transform: translateY(-50px); /* Posición inicial de la animación */
  transition: opacity 0.8s ease, transform 0.8s ease;
}

/* Animaciones */
.fade-in-down {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.breathe {
  animation: breathe 4s ease-in-out infinite;
}

@keyframes breathe {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.06);
  }
}

/* Animación FlipInX */
@keyframes flip-in-x {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flip-in-x {
  animation: flip-in-x 0.8s ease-out both;
}

.hidden {
  opacity: 0;
  display: none;
}

/* Contenedor general */
.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.grid-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 600px;
  box-sizing: border-box;
  position: relative;
}

.image-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
}

.left-image-1 {
  width: 55%;
  height: auto;
}

.left-image-2 {
  width: 45%;
  height: auto;
}

.right-image-1 {
  width: 50%;
  height: auto;
}

.right-image-2 {
  width: 40%;
  height: auto;
}

.banner-left {
  width: 68%;
  height: auto;
  position: relative;
  bottom: 0;
}

.banner-right {
  width: 68%;
  height: auto;
  position: relative;
  bottom: 0;
}

.left-container {
  margin-right: 10px;
  align-self: flex-start;
}

.right-container {
  margin-left: 10px;
  align-self: flex-start;
}

p {
  color: #375682;
  font-size: 67%;
  margin: 0;
}

/* Media Query para el formato 1180x820px */
@media screen and (max-width: 1180px) and (max-height: 820px) {
  .hero-section {
    padding-top: 10px;
  }
  .logo {
    width: 15%;
  }
  .title {
    width: 90%;
    max-width: 710px;
  }
  .container {
    flex-direction: row;
    align-items: flex-start;
  }
  .grid-container {
    width: 50%;
    height: 600px;
  }
  .image-row {
    gap: 5px;
  }
  .left-image-1 {
    width: 38%;
    position: absolute;
    bottom: 136px;
    right: 262px;
  }
  .left-image-2 {
    width: 71%;
    position: absolute;
    bottom: 85px;
    left: 159px;
    z-index: 2;
  }
  .right-image-1 {
    width: 110%;
    position: absolute;
    bottom: 116px;
    right: 0;
    z-index: 2;
  }
  .right-image-2 {
    width: 38%;
    position: absolute;
    top: 304px;
    left: 265px;
  }
}

/* Responsividad para pantallas medianas (Tablet) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    flex-direction: row;
    align-items: flex-start;
  }
  .grid-container {
    width: 50%;
    height: 600px;
  }
}

/* Responsividad para pantallas pequeñas (Móviles) */
@media screen and (max-width: 768px) {
  .logo {
    width: 100px;
    top: 0;
    left: 10px;
  }
  .title {
    width: 100%;
    margin-top: 70px;
  }
  .container {
    flex-direction: column;
    align-items: center;
  }
  .grid-container {
    width: 100%;
    height: auto;
  }
  .image-row {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
