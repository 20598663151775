<template>
  <section class="fullscreen-section" ref="section">
    <img
      class="logo"
      :class="{ 'animate-slide-down': isLogoVisible }"
      src="../assets/section-one/Logo Colgate.png"
      alt="Logo"
    />
    <img
      class="top-right-image"
      :class="{ 'animate-slide-left': isBannerVisible }"
      src="../assets/section-five/Banner Nuevo.png"
      alt="Top Right Image"
    />
    <img
      class="top-centered-image"
      :class="{ 'animate-rotate': isCepilloVisible }"
      src="../assets/section-five/Logo Cepillo.png"
      alt="Top Centered Image"
    />
    <img
      class="below-top-centered-image"
      :class="{ 'animate-drop-in': isTextosVisible }"
      src="../assets/section-five/Textos.png"
      alt="Below Top Centered Image"
    />
    <img
      class="centered-image"
      src="../assets/section-five/Círcilo fondo.png"
      alt="Centered Image"
    />
    <img
      class="overlay-image"
      :class="{ 'animate-fade-in': isCremaDentalVisible, 'breathe-animation': isCremaDentalVisible }"
      src="../assets/section-five/CremaDental.png"
      alt="Overlay Image"
      @click="showCuadrosTexto"
    />

    <!-- Contenedor centrado debajo de CremaDental.png -->
    <div class="below-overlay-container">
      <img
        class="side-image"
        :class="{ 'animate-flip': isCuadrosTextoVisible }"
        src="../assets/section-five/Cuadro texto 1.png"
        alt="Side Image 1"
      />
      <img
        class="side-image"
        :class="{ 'animate-flip': isCuadrosTextoVisible }"
        src="../assets/section-five/Cuadro texto 2.png"
        alt="Side Image 2"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionFive",
  data() {
    return {
      isLogoVisible: false,
      isBannerVisible: false,
      isCepilloVisible: false,
      isTextosVisible: false,
      isCremaDentalVisible: false,
      isCuadrosTextoVisible: false, 
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isLogoVisible = true;
            this.isBannerVisible = true;
            this.isCepilloVisible = true;
            this.isTextosVisible = true;
            this.isCremaDentalVisible = true;
          }
        });
      },
      {
        threshold: 0.5, // Detect when 50% of the section is visible
      }
    );

    observer.observe(this.$refs.section);
  },
  methods: {
    showCuadrosTexto() {
      this.isCuadrosTextoVisible = true;
    },
  },
};
</script>

<style scoped>
.fullscreen-section {
  position: relative;
  width: 100vw;
  height: 150vh;
  background-image: url("../assets/section-four/Fondo.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Logo Animation */
.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 15%;
  height: auto;
  transform: translateY(-100px); /* Initial position above the viewport */
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease, opacity 1s ease;
}

.animate-slide-down {
  transform: translateY(0); /* Moves to its original position */
  opacity: 1; /* Becomes visible */
}

/* Banner Animation */
.top-right-image {
  position: absolute;
  top: 90px;
  right: 0;
  width: 23%;
  height: auto;
  transform: translateX(100px); /* Initial position outside the viewport (right) */
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease, opacity 1s ease;
}

.animate-slide-left {
  transform: translateX(0); /* Moves to its original position */
  opacity: 1; /* Becomes visible */
}

/* Rotate Animation for Logo Cepillo */
.top-centered-image {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg); /* Initial rotation */
  width: 30%;
  height: auto;
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease, opacity 1s ease;
}

.animate-rotate {
  transform: translateX(-50%) rotate(0); /* Rotates to its original position */
  opacity: 1; /* Becomes visible */
}

/* Drop In Animation for Textos */
.below-top-centered-image {
  position: absolute;
  top: 80px; /* Keeps the original position */
  left: 50%;
  transform: translateX(-50%); /* No movement horizontally */
  width: 25%;
  height: auto;
  opacity: 0; /* Initially hidden */
  z-index: 2;
  transition: opacity 1s ease, transform 1s ease;
}

.animate-drop-in {
  opacity: 1; /* Becomes visible */
  transform: translateX(-50%) scale(1); /* Maintains its original scale and position */
}

/* CremaDental Fade In and Breathe Animation */
.overlay-image {
  max-width: 60%;
  max-height: 60%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  z-index: 2;
  opacity: 0; /* Initially hidden */
  transition: transform 5s ease, opacity 5s ease; /* Slower and smoother animation */
}

.animate-fade-in {
  opacity: 1; /* Becomes visible */
  transform: translate(-50%, -50%) scale(1); /* Returns to original size */
}

.breathe-animation {
  animation: breathe 3s ease-in-out infinite; /* Continuous breathing effect */
}

@keyframes breathe {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

/* Flip Animation for Cuadros Texto */
.side-image {
  width: 30%;
  height: auto;
  object-fit: contain;
  opacity: 0; /* Initially hidden */
  transform: rotateY(90deg); /* Start flipped */
  transition: transform 0.6s ease, opacity 0.6s ease; /* Smooth transition */
}

.animate-flip {
  opacity: 1; /* Becomes visible */
  transform: rotateY(0deg); /* Flips to the original position */
}

/* Other Styles */
.below-overlay-container {
  position: absolute;
  top: calc(50% + 140px); /* Positioned right below CremaDental.png */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 3;
}

@media screen and (max-width: 1180px) and (max-height: 820px) {
  .fullscreen-section {
    background-position: top;
  }

  .logo {
    top: 0;
    left: 50px;
    width: 15%;
  }

  .top-right-image {
    top: 90px;
    right: 0;
    width: 23%;
  }

  .top-centered-image {
    top: 60px;
    width: 15%;
  }

  .below-top-centered-image {
    top: 226px;
    width: 60%;
  }

  .centered-image {
    max-width: 60%;
    max-height: 60%;
  }

  .overlay-image {
    max-width: 70%;
    max-height: 70%;
  }

  .below-overlay-container {
    top: calc(58% + 110px); /* Adjusted for smaller screens */
    gap: 54px;
  }

  .side-image {
    width: 42%;
  }
}
</style>
