<template>
  <section class="responsive-section" @scroll="handleScroll">
    <img src="../assets/section-one/Logo Colgate.png" alt="Logo" class="section-logo animated" />
    <img src="../assets/section-two/Título Total.png" alt="Title Image" class="section-title-image animated" />
    <img src="../assets/section-two/manos.png" alt="Centered Image" class="section-center-image animated" />
    <img src="../assets/section-two/banner.png" alt="Footer Image" class="section-footer-image animated" />
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSethc7s5pG_a_wV_URhmr7jjruRu7QMLr4Xlu_2phXv7vzGpg/viewform?usp=header" class="section-bottom-button animated">Preguntas</a>
    <img src="../assets/section-two/qr-form.png" class="qr animated" />
  </section>
</template>

<script>
export default {
  name: "SectionTwo",
  methods: {
    handleScroll() {
      const elements = document.querySelectorAll(".animated");
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          element.classList.add("visible");
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* Estilo base de la sección */
.responsive-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../assets/section-two/Fondo.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

/* Estilo base de los elementos dentro de esta sección */
.section-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
  height: auto;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 4s cubic-bezier(0.25, 1, 0.5, 1);
}

.section-title-image {
  position: absolute;
  top: 80px;
  width: 70%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
  opacity: 0;
  transform: translateX(-100px);
  transition: all 4s cubic-bezier(0.25, 1, 0.5, 1);
}

.section-center-image {
  width: 100%;
  max-width: 1100px;
  height: auto;
  object-fit: contain;
  align-self: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 4s cubic-bezier(0.25, 1, 0.5, 1);
}

.section-footer-image {
  width: 80%;
  max-width: 1000px;
  height: auto;
  object-fit: contain;
  margin-top: 20px;
  align-self: center;
  opacity: 0;
  transform: translateY(100px);
  transition: all 4s cubic-bezier(0.25, 1, 0.5, 1);
}

.section-bottom-button {
  margin-top: 20px;
  padding: 15px 30px;
  background: #F50005;
  border: none;
  border-radius: 25px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  align-self: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 90px;
  opacity: 0;
  transform: scale(0.8);
  transition: all 4.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.qr {
  width: 10%;
  position: absolute;
  right: 31%;
  bottom: 6%;
  opacity: 0;
  transform: translateX(100px);
  transition: all 4s cubic-bezier(0.25, 1, 0.5, 1);
}

/* Animaciones activadas al hacer scroll */
.visible {
  opacity: 1;
  transform: translateX(0) translateY(0) scale(1);
}

/* Diseño responsivo */
@media (max-width: 1180px) and (max-height: 820px) {
  .responsive-section {
    height: 820px;
  }

  .section-logo {
    top: 0;
    left: 50px;
    width: 15%;
  }

  .section-title-image {
    top: 54px;
    width: 60%;
    max-width: 400px;
  }

  .section-center-image {
    width: 100%;
    max-width: 1400px;
  }

  .section-footer-image {
    width: 40%;
    max-width: 800px;
    margin-top: 10px;
  }

  .section-bottom-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
</style>
