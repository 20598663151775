<template>
  <section class="colgate-section" ref="section">
    <div class="logo-container">
      <img
        src="../assets/section-one/Logo Colgate.png"
        alt="Logo Colgate"
        class="logo animated"
      />
    </div>
    <div class="content">
      <div class="left-container" style="padding-top: 80px;">
        <img
          src="../assets/section-nine/titulo_1.png"
          alt="Imagen 1"
          class="image left-image animated"
        />
        <img
          src="../assets/section-nine/Textos_1.png"
          alt="Imagen 2"
          class="image left-image animated"
        />
        <img
          src="../assets/section-nine/texto 2 Sensitive.png"
          alt="Imagen 3"
          class="image left-image animated"
        />
        <img
          src="../assets/section-nine/Claim_1.png"
          alt=""
          class="text-footer"
        />
      </div>
      <div class="right-container" style="position: relative;">
        <img
          src="../assets/section-nine/cepillo.png"
          alt="Imagen Derecha"
          class="image right-image touchpoint"
          @click="handleTouchPoint"
        />
        <img
          v-show="visibleImages[0]"
          src="../assets/section-nine/Cerdas.png"
          alt="Nueva Imagen 1"
          class="image right-new-image"
        />
        <img
          v-show="visibleImages[1]"
          src="../assets/section-nine/Cuello.png"
          alt="Nueva Imagen 2"
          class="image right-new-image"
        />
        <img
          v-show="visibleImages[2]"
          src="../assets/section-nine/Mango.png"
          alt="Nueva Imagen 3"
          class="image right-new-image"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionNine",
  data() {
    return {
      visibleImages: [false, false, false],
      clickCounter: 0,
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const images = entry.target.querySelectorAll(".animated");
            images.forEach((img) => {
              img.classList.add("animate-in");
            });
            this.observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    this.observer.observe(this.$refs.section);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleTouchPoint() {
      if (this.clickCounter < this.visibleImages.length) {
        this.visibleImages[this.clickCounter] = true;
        this.clickCounter += 1;
      }
    },
  },
};
</script>

<style scoped>
.colgate-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/section-nine/fondo.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 50px;
}

.logo {
  width: 177px;
  height: auto;
}

.animated {
  opacity: 0;
  transform: translateY(50px) scale(0.8) rotate(-10deg);
  transition: opacity 1s ease, transform 1s cubic-bezier(0.25, 1, 0.5, 1);
}

.animate-in {
  opacity: 1;
  transform: translateY(0) scale(1) rotate(0deg);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1180px;
  margin-top: 60px;
}

.left-container,
.right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.image {
  width: 60%;
  margin-bottom: 20px;
  border-radius: 10px;
}

.left-container .left-image {
  width: 80%;
}

.right-container .right-image {
  align-self: flex-start;
  margin-left: 0;
}

.right-container .right-new-image {
  position: absolute;
  width: 25%;
  left: 180px;
}

.right-container .right-new-image:nth-child(2) {
  top: 0;
}

.right-container .right-new-image:nth-child(3) {
  top: 30%;
}

.right-container .right-new-image:nth-child(4) {
  top: 60%;
}

.text-footer {
  margin-top: 76px;
}

@media (max-width: 1180px) and (max-height: 820px),
  (min-width: 768px) and (max-width: 1024px) {
  .colgate-section {
    padding: 10px;
  }

  .logo {
    width: 177px;
  }

  .content {
    flex-direction: row;
  }

  .left-container,
  .right-container {
    width: 50%;
  }

  .image {
    width: 62%;
    margin-bottom: 10px;
  }

  .left-container .left-image {
    width: 70%;
  }

  .right-container .right-new-image {
    width: 44%;
  }
}

@media (max-width: 767px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .left-container,
  .right-container {
    width: 100%;
  }

  .image {
    width: 50%;
    margin-bottom: 10px;
  }

  .left-container .left-image {
    width: 60%;
  }

  .right-container .right-new-image {
    width: 25%;
  }

  .right-container .right-new-image:nth-child(2) {
    top: 0;
  }

  .right-container .right-new-image:nth-child(3) {
    top: 40%;
  }

  .right-container .right-new-image:nth-child(4) {
    top: 80%;
  }
}
</style>
