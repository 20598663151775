<template>
  <section class="full-screen-section">
    <img src="../assets/section-one/Logo Colgate.png" alt="Logo" class="logo animate-on-scroll" />
    <img src="../assets/section-three/Círculo.png" alt="Top Image" class="above-circle-image animate-on-scroll" />
    <div class="circle-container animate-on-scroll">
      <img src="../assets/section-three/Texto Círculo.png" alt="Centered Image" class="center-image pulse-animation animate-on-scroll" />
    </div>
    <img src="../assets/section-two/Título Total.png" alt="Top Centered Image" class="top-center-image animate-on-scroll" />
    <img src="../assets/section-three/placa.png" alt="Left Image" class="left-image slide-animation animate-on-scroll" />
    <img src="../assets/section-three/Boca.png" alt="Bottom Image" class="bottom-image animate-on-scroll touch-point" @click="revealNextImage" />
    <p class="animate-on-scroll">Chakraborty B, Seriwatanachai D, Triratana T, Mateo LR, D'Ambrogio R, Xu G, Ryan M, Zhang YP. Antibacterial Effects of a Novel Stannous Fluoride Toothpaste Stabilized With Nitrate and Phosphates (SNaP): In Vitro Study and Randomized Controlled Trial. Compend Contin Educ Dent. 2024 Nov-Dec;45(Suppl 3):12 - 19.</p>
    <img src="../assets/section-three/Dientes.png" alt="Right Image 1" class="right-image right-image-1 hidden-image animate-on-scroll" />
    <img src="../assets/section-three/Carrillos.png" alt="Right Image 2" class="right-image right-image-2 hidden-image animate-on-scroll" />
    <img src="../assets/section-three/Saliva.png" alt="Right Image 3" class="right-image right-image-3 hidden-image animate-on-scroll" />
    <img src="../assets/section-three/Encías.png" alt="Left Image 1" class="left-extra-image left-extra-image-1 hidden-image animate-on-scroll" />
    <img src="../assets/section-three/Lengua.png" alt="Left Image 2" class="left-extra-image left-extra-image-2 hidden-image animate-on-scroll" />
  </section>
</template>

<script>
export default {
  name: "FullScreenSection",
  data() {
    return {
      currentImageIndex: 0,
      images: [
        ".right-image-1",
        ".right-image-2",
        ".right-image-3",
        ".left-extra-image-1",
        ".left-extra-image-2"
      ],
    };
  },
  methods: {
    revealNextImage() {
      if (this.currentImageIndex < this.images.length) {
        const nextImage = document.querySelector(this.images[this.currentImageIndex]);
        if (nextImage) {
          nextImage.classList.remove("hidden-image");
          nextImage.classList.add("visible-image");
        }
        this.currentImageIndex += 1;
      }
    },
  },
  mounted() {
    const elements = document.querySelectorAll(".animate-on-scroll");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Deja de observar una vez que el elemento es visible
          }
        });
      },
      { threshold: 0.1 } // El elemento debe estar al menos un 10% visible
    );

    elements.forEach((el) => observer.observe(el));
  },
};
</script>

<style scoped>
.full-screen-section {
  position: relative;
  width: 100%;
  height: 133vh;
  background-image: url('../assets/section-two/Fondo.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

p {
  position: absolute;
  bottom: 0;
  font-family: sans-serif;
  color: #1a3467;
  font-size: 12px;
  text-align: center;
}

/* Animaciones */
.animate-on-scroll {
  opacity: 0;
  transform: none; /* No altera la ubicación de los elementos */
  transition: opacity 0.6s ease-out;
}

.animate-on-scroll.visible {
  opacity: 1;
}

.hidden-image {
  display: none;
}

.visible-image {
  display: block;
}

/* Animación de pulso */
.pulse-animation {
  animation: pulse 11s infinite;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Animación de desplazamiento */
.slide-animation {
  animation: slide 6s infinite;
}

@keyframes slide {
  0%, 100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateX(-28px);
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 15%;
  height: auto;
}

.circle-container {
  width: 350px;
  height: 350px;
  background-color: #E3E8EB;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-box-shadow: 0px 0px 26px 15px rgba(227, 232, 235, 1);
  -moz-box-shadow: 0px 0px 26px 15px rgba(227, 232, 235, 1);
  box-shadow: 0px 0px 26px 15px rgba(227, 232, 235, 1);
}

.center-image {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
}

.above-circle-image {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: auto;
  z-index: 1;
}

.left-image {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 100px;
  height: auto;
  z-index: 1;
}

.bottom-image {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  height: auto;
  z-index: 2;
  top: 61%;
}

.right-image {
  position: absolute;
  top: 63%;
  height: 100px;
  width: auto;
  z-index: 2;
}

.right-image-1 {
  left: 55%;
}

.right-image-2 {
  left: 62%;
}

.right-image-3 {
  left: 69%;
}

.left-extra-image {
  position: absolute;
  top: 61%;
  height: 100px;
  width: auto;
  z-index: 2;
}

.left-extra-image-1 {
  left: 43%;
}

.left-extra-image-2 {
  left: 36%;
}

.top-center-image {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 22%;
  height: auto;
}

@media (max-width: 1180px) and (max-height: 820px) {
  .logo {
    width: 15%;
    top: 0;
    left: 50px;
  }

  .circle-container {
    width: 450px;
    height: 450px;
  }

  .center-image {
    max-width: 98%;
    max-height: 98%;
  }

  .above-circle-image {
    top: 208px; /*233*/
    width: 56%;
    left: 51%;
  }

  .left-image {
    top: 42.3%;
    left: 266px;
    width: 29%;
  }

  .bottom-image {
    width: 350px;
  }

  .right-image,
  .left-extra-image {
    height: 80px;
  }

  .right-image-1 {
    left: 21%;
    top: 64%;
  }

  .right-image-2 {
    left: 21%;
    top: 72%;
  }

  .right-image-3 {
    left: 21%;
    top: 80%;
  }

  .left-extra-image-1 {
    left: 53%;
  }

  .left-extra-image-2 {
    left: 50%;
    top: 77%;
  }

  .top-center-image {
    width: 22%;
    top: 80px;
  }
}
</style>