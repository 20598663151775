<template>
  <section class="custom-section" ref="section">
    <!-- Logo en la esquina superior izquierda -->
    <img class="logo" src="../assets/section-one/Logo Colgate.png" alt="Logo">

    <!-- Imagen en la esquina superior derecha -->
    <img class="top-right-image" src="../assets/section-four/Logo PerioGARD.png" alt="Top Right Image">

    <!-- Contenedor al lado izquierdo -->
    <div class="left-container">
      <img
        class="left-image title-image"
        ref="titleImage"
        src="../assets/section-four/Título.png"
        alt="Left Image 1"
      />
      <img
        class="left-image text-image"
        ref="textImage"
        src="../assets/section-four/Texto PerioGard_1.png"
        alt="Left Image 2"
      />
      <div class="bottom-images">
        <img class="bottom-image" src="../assets/section-four/Cepillado.png" alt="Bottom Image 1" />
        <img class="overlay-bottom-image" src="../assets/section-four/Más.png" alt="" srcset="" />
        <img
          class="bottom-image"
          src="../assets/section-four/Control quimico.png"
          alt="Bottom Image 2"
        />
      </div>
    </div>

    <!-- Imagen centrada en la sección -->
    <img class="centered-image" src="../assets/section-four/Círcilo fondo.png" alt="Centered Image" />
    <img class="overlay-image regimen-image" ref="regimenImage" src="../assets/section-four/Régimen.png" alt="">
    <img class="sello sello-image" ref="selloImage" src="../assets/section-four/Sello Low Stain.png" alt="">

    <!-- Párrafo en la parte inferior de la sección -->
    <p class="bottom-paragraph">
      Poppolo Deus F, Ouanounou A. Chlorhexidine in Destiny: Pharmacology, Uses, and Adverse Effects.
      Int Dent J. 2022 Jun;72(3):269-277.
    </p>
  </section>
</template>

<script>
export default {
  name: "SectionFour",
  mounted() {
    const options = {
      threshold: 0.5,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
          observer.unobserve(entry.target); // Deja de observar después de la animación de entrada
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Observa las imágenes
    const titleImage = this.$refs.titleImage;
    const textImage = this.$refs.textImage;
    const regimenImage = this.$refs.regimenImage;
    const selloImage = this.$refs.selloImage;

    observer.observe(titleImage);
    observer.observe(textImage);
    observer.observe(regimenImage);
    observer.observe(selloImage);
  },
};
</script>

<style scoped>
.custom-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../assets/section-four/Fondo.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Necesario para detectar el scroll */
}

.left-image {
  width: 54%;
  height: auto;
}

.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 150px;
  height: auto;
}

.top-right-image {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  height: auto;
}

.left-container {
  position: absolute;
  left: 50px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.bottom-images {
  display: flex;
}

.bottom-image {
  width: 25%;
  height: auto;
}

.overlay-bottom-image {
  position: absolute;
  left: 19%;
  top: 68%;
  width: 127px;
}

.centered-image {
  max-width: 40%;
  max-height: 80%;
  object-fit: contain;
  align-self: flex-end;
  margin-right: 5px;
}

.overlay-image {
  width: 42%;
  align-self: flex-end;
  position: absolute;
  right: 50px;
}

.sello {
  width: 12%;
  position: absolute;
  top: 62%;
  left: 85%;
}

.bottom-paragraph {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  color: #1a3467;
}

/* Estilo inicial para las imágenes con opacidad 0 y desplazamiento hacia la izquierda */
.title-image,
.text-image {
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 2s ease-out, opacity 2s ease-out;
}

/* Animación de entrada para Título y Texto */
.title-image.animate,
.text-image.animate {
  opacity: 1;
  transform: translateX(0);
}

/* Estilo inicial para Régimen y Sello */
.regimen-image,
.sello-image {
  opacity: 0;
  transform: translateY(50px);
  transition: transform 2s ease-out, opacity 2s ease-out;
}

/* Animación de entrada para Régimen y Sello */
.regimen-image.animate,
.sello-image.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Animación de respiro para Régimen y Sello */
.regimen-image.animate,
.sello-image.animate {
  animation: breathing 3s ease-in-out infinite;
}

@keyframes breathing {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

/* Media queries */
@media screen and (max-width: 1180px) and (max-height: 820px) {
  .custom-section {
    flex-direction: column;
  }

  .centered-image {
    max-width: 46%;
  }

  .logo {
    width: 15%;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 80px;
  }

  .centered-image {
    max-width: 80%;
  }

  .top-right-image {
    width: 100px;
  }

  .bottom-paragraph {
    font-size: 12px;
  }
}
</style>
